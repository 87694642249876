
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { FileSearchOutlined } from "@ant-design/icons-vue";
import useSpotCheckProgramDealer from "@/hooks/checking/spotChecking/checkingConfirm/useSpotCheckProgramDealer";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import {
  fetchDealerConfirm,
  fetchDealerReturn,
  fetchConfirmtoSIPO,
  downloadWordingFile,
  dealerVerifyReturn,
  dealerVerifyConfirm, dealerAddComments2, getCheckBeforeConfirm,
} from "@/API/checking/spotChecking";
import ReturnConfirmModal from "../components/returnModalConfirm.vue";
import downloadFile from "@/utils/payment/downloadFile";
import GetColumnWidth from "@/utils/payment/getColumnWidth";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const {
      data,
      column,
      summary,
      timeLine,
      id,
      status,
      statusList,
      getStatusList,
      getTableData,
      isAllConfirmToSipo,
      isAllControllingReviewed,
    } = useSpotCheckProgramDealer();
    const search = () => {
      getTableData();
    };
    const reset = () => {
      status.value = "";
      getTableData();
    };
    const returnModalVisible = ref(false);
    const returnCommentModalVisible = ref(false);
    const handleReturn = async () => {
      const result = await dealerVerifyReturn([id.value]);
      if (!result.data) {
        // message.warning(result.message);
      } else {
        returnModalVisible.value = true;
      }
    };
    const returnVisible = ref(false);
    const pageComments = ref('');
    const handleCloseReturnVisible = ()=>{
      returnVisible.value=false
      pageComments.value=''
    }
    const handleReturnConfirm = async (comment: string) => {
      if (comment.length === 0) {
        message.error('Reason is required')
        return
      }
      await dealerAddComments2(comment, [id.value])
      await fetchDealerReturn([id.value])
      search()
      message.success("Return successfully!")
      returnCommentModalVisible.value = false;
    };
    const handleConfirm = async (isAddCom: any) => {
      if (pageComments.value.length===0&&isAddCom){
        message.error('Reason is required')
      } else {
        if (isAddCom) {
          dealerAddComments2(pageComments.value, [id.value])
        }
        const result = await dealerVerifyConfirm([id.value]);
        if (!result.data) {
          // message.warning(result.message);
          return;
        }
        fetchDealerConfirm([id.value]).then((res) => {
          search();
          message.success("Confirm successfully!");
        });
        handleCloseReturnVisible()
      }

    };
    const isConfirm = ()=>{
      const dealerIdArr=ref<any>([])
      data.value.forEach((i: any,ind: any)=>{
        dealerIdArr.value.push(i.id)
      })
      const params = {
        ids:[id.value],
      }
      getCheckBeforeConfirm(params).then(res=>{
        console.log(320,res)
        if (res){
          returnVisible.value=true
        } else {
          handleConfirm(false)
        }
        //
      })
    }
    const handleConfirmSIPO = async () => {
      const res = await fetchConfirmtoSIPO([id.value]);
      if (res.code === '0') {
        search();
        message.success("ConfirmSIPO successfully!");
      }
    };
    const router = useRouter();
    const { commit } = useStore();
    const handleGoTo = (record: any) => {
      commit("spotChecking/updateSpotCheckConfirmProgramDealer", record);
      router.push({ path: "/spotCheckingControlling/dealerVinView" });
    };
    const init = () => {
      getStatusList();
      getTableData();
    };
    init();
    const handleExport = (record: any) => {
      const params = {
        url: `/claimapi/spotCheckConfirm/dealer/exportExcel?programOfferTypeId=${record.offerTypeId}`,
        method: "post",
      };
      downloadFile(params);
    };
    // const handleExportWordingFile = (record: any) => {
    //   const params = {
    //     url: `/claimapi/spotCheckConfirm/fl/wordingFileDownload?programCodeOfferTypeId=${record.offerTypeId}`,
    //     method: "post",
    //   };
    //   downloadFile(params).catch((error) => {
    //       message.error(error)
    //     });
    // };
    const handleExportWordingFile = (record: any) => {
      downloadWordingFile(record.offerTypeId).then((data: any) => {
        if (data.zipDownloadUrl) {
          window.location.href = data.zipDownloadUrl;
        }
      });
    };
    const tableWidth = ref(GetColumnWidth(column));
    return {
      handleCloseReturnVisible,
      isConfirm,
      pageComments,
      returnVisible,
      data,
      column,
      summary,
      timeLine,
      status,
      statusList,
      isPagination: false,
      search,
      reset,
      handleGoTo,
      handleConfirm,
      handleReturn,
      handleReturnConfirm,
      returnModalVisible,
      returnCommentModalVisible,
      handleConfirmSIPO,
      isAllConfirmToSipo,
      isAllControllingReviewed,
      handleExport,
      handleExportWordingFile,
      tableWidth
    };
  },
  components: {
    TableWrapper,
    // FileDoneOutlined,
    FileSearchOutlined,
    ReturnConfirmModal,
  },
});
